<template>
	<b-modal
		id="confirm-send-po-dialog"
		v-model="isOpen"
		:title="FormMSG(51, 'Confirm')"
		header-class="header-class-modal-doc-package"
		size="sm"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleOk"
	>
		<div>
			<div class="fs-14 text-color-rhapsody-in-blue mb-4">
				{{ FormMSG(54, 'Please select the recipient:') }}
			</div>
			<b-row>
				<b-col cols="12">
					<b-form-group :label="FormMSG(56, 'Share PO to:')">
						<b-form-input
							v-model="$v.contactEmail.$model"
							:placeholder="FormMSG(857, 'Enter email')"
							:state="getStateValidationField('contactEmail')"
						/>
						<div v-if="!$v.contactEmail.required && getStateValidationField('contactEmail') !== null" class="invalid-feedback">
							{{ FormMSG(859, 'Please enter email') }}
						</div>
						<div v-if="!$v.contactEmail.email && getStateValidationField('contactEmail') !== null" class="invalid-feedback">
							{{ FormMSG(860, 'Please enter an email valid') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ cancel, ok }" style="position: relative">
			<div class="w-100">
				<b-row>
					<b-col sm="12" md="6" lg="6" xl="6">
						<b-button variant="outline-primary" block @click="cancel">
							{{ FormMSG(53, 'Cancel') }}
						</b-button>
					</b-col>
					<b-col sm="12" md="6" lg="6" xl="6">
						<b-button variant="primary" block @click="ok">
							{{ FormMSG(52, 'Send') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'ConfirmSendPoDialog',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			contactEmail: ''
		};
	},

	validations: {
		contactEmail: { required, email }
	},

	watch: {
		currentJobRequest: {
			handler(newVal) {
				this.contactEmail = newVal.supplier.email;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		handleOk() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.$emit('confirm-send-po-dialog:send', this.contactEmail);
				this.emitEventClose();
			}
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		},
		emitEventClose() {
			this.$emit('confirm-send-po-dialog:close');
		}
	}
};
</script>
