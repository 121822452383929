var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "pos-relative",
            attrs: { id: "detailsJobRequestContainer" },
          },
          [
            _c(
              "div",
              {
                class: {
                  "container-layout": _vm.$screen.width > 576,
                  "container-mobile": _vm.$screen.width <= 576,
                },
              },
              [
                _vm.$screen.width <= 576
                  ? _c("div", { staticClass: "title" }, [
                      _c(
                        "h1",
                        {
                          staticClass: "main-page-title",
                          class: { "is-pwa": _vm.$isPwa() },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(718, "Job Request")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cursor-pointer fs-24 fw-700",
                                class: [
                                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                                  { "is-pwa": _vm.$isPwa() },
                                ],
                                on: { click: _vm.goBack },
                              },
                              [
                                _c(_vm.getLucideIcon("ArrowLeft"), {
                                  tag: "component",
                                  attrs: { size: 22, "stroke-width": 3 },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.FormMSG(18, "Job Request")) +
                                    " #" +
                                    _vm._s(
                                      _vm.currentJobRequest.jobRequestNumber
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t- " +
                                    _vm._s(_vm.currentJobRequest.fullName) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-end" },
                              [
                                _vm.showViewAttachedDocument
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "bg-color-white mr-2",
                                        attrs: { variant: "outline-primary" },
                                        on: {
                                          click: _vm.handleClickViewDocument,
                                        },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Eye"), {
                                          tag: "component",
                                          attrs: { size: 16 },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.FormMSG(
                                                45,
                                                "View attached document"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: {
                                      id: "dropdown-action-selection",
                                      variant: "outline-primary",
                                      disabled: _vm.loadingActionSelection,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _vm.loadingActionSelection
                                              ? _c("b-spinner", {
                                                  attrs: { small: "" },
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    569,
                                                    "Change status"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  },
                                  [
                                    _vm.currentJobRequest.status !== 0
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickChangeStatus(
                                                  0
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "RotateCcw"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 18 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          815,
                                                          "Reset selection status"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.currentJobRequest.status !== 1
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickChangeStatus(
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon("Send"),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 18 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          715,
                                                          "Mars as PO SENT"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.currentJobRequest.status !== 2
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickChangeStatus(
                                                  2
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Clock4"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 18 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          716,
                                                          "Mark as PO CONFIRMED"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.currentJobRequest.status !== 3
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickChangeStatus(
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Check"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 18 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          717,
                                                          "Mark as CLOSED"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-2 d-flex align-items-center",
                                  },
                                  [
                                    _c("div", { staticClass: "wrap-status" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "status fw-700",
                                          class: {
                                            validated:
                                              _vm.currentJobRequest.status ===
                                              3,
                                            "not-submitted":
                                              _vm.currentJobRequest.status ===
                                              0,
                                            info:
                                              _vm.currentJobRequest.status ===
                                              1,
                                            pending:
                                              _vm.currentJobRequest.status ===
                                              2,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(_vm.labelStatus) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "tab-budget mt-3" },
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            {
                              staticClass: "pos-relative",
                              class: `${
                                _vm.$screen.width <= 576 ? "my-project" : ""
                              }`,
                            },
                            [
                              _c(
                                "b-tabs",
                                {
                                  attrs: {
                                    value: _vm.jobRequestTabs,
                                    cards: "",
                                    "active-nav-item-class": "font-weight-bold",
                                    "active-tab-class": "font-weight-bold",
                                  },
                                  model: {
                                    value: _vm.tabIndex,
                                    callback: function ($$v) {
                                      _vm.tabIndex = $$v
                                    },
                                    expression: "tabIndex",
                                  },
                                },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          106,
                                          "Personal detail"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c(
                                          "div",
                                          [
                                            _c("profile-screen-to-tab", {
                                              attrs: {
                                                "for-job-request": "",
                                                "external-user-id":
                                                  +_vm.currentJobRequest.userId,
                                                "hide-password-section": "",
                                                "hide-btn-save": "",
                                                "hide-action-change-picture":
                                                  "",
                                                "hide-place-of-birth-zip": "",
                                                "hide-maiden-name": "",
                                                "hide-planning-color": "",
                                                "hide-info-identification": "",
                                                "hide-bank-account-owner-name":
                                                  "",
                                                "hide-declaration-country": "",
                                                "hide-add-postal-address": "",
                                                "hide-info-health": "",
                                                "hide-personal-vehicle-section":
                                                  "",
                                                "hide-info-other": "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          107,
                                          "Company & Project detail"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c(
                                          "div",
                                          [_c("company-project-detail")],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          108,
                                          "Contract detail"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c("div", [_c("contract-detail")], 1),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(
                                          1111,
                                          "Authorisation"
                                        ),
                                      },
                                    },
                                    [_c("UserAuthorisation")],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          109,
                                          "Send document"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c(
                                          "div",
                                          [
                                            _c("send-document-detail", {
                                              on: {
                                                "send-document-detail:send-po-success":
                                                  _vm.handleSendDocumentDetailSendPOSuccess,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }