<template>
	<div>
		<div v-if="!userIsCreated">
			<b-row>
				<b-col>
					<b-alert show dismissible variant="warning">
						<component :is="getLucideIcon('AlertTriangle')" :size="20" />
						{{ FormMSG(415, 'You must add user in project for configure user authorization') }}
					</b-alert>
				</b-col>
			</b-row>
		</div>
		<div v-else class="content-card-collapsible">
			<div>
				<div>
					<b-row v-if="stateError.show">
						<b-col>
							<b-alert variant="danger" show dismissible @dismissed="stateError.show = false">
								{{ stateError.msg }}
							</b-alert>
						</b-col>
					</b-row>
					<b-row>
						<b-col sm="12" md="12">
							<fieldset class="card-inside">
								<legend class="card-inside">
									{{ FormMSG(301, 'Application rights') }}
								</legend>
								<b-row style="padding-left: 30px">
									<b-col md="4" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageCrewList"
											:value="true"
											class="pj-cb pb-1"
											:unchecked-value="false"
										>
											{{ FormMSG(2970, 'Manage crew list') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" v-if="useDoc" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageDocuments"
											:value="true"
											class="pj-cb pb-1"
											:unchecked-value="false"
										>
											{{ FormMSG(2971, 'Manage documents') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useDoc" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageDocumentPackage"
											:value="true"
											:unchecked-value="false"
											class="pj-cb pb-1"
										>
											{{ FormMSG(2440, 'Manage document package') }}
										</b-form-checkbox>
									</b-col>
									<b-col v-if="useCallSheet" md="4" class="mb-3">
										<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="roleUserData.canManageCallSheets">
											{{ FormMSG(2450, 'Manage call sheets') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" v-if="useAcc" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageAccommodations"
											:value="true"
											class="pj-cb pb-1"
											:unchecked-value="false"
										>
											{{ FormMSG(2973, 'Manage accomodations') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useLoc" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageLocations"
											:value="true"
											:unchecked-value="false"
											class="pj-cb pb-1"
										>
											{{ FormMSG(2449, 'Manage locations') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useTrans" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageTransport"
											:value="true"
											:unchecked-value="false"
											class="pj-cb pb-1"
										>
											{{ FormMSG(20449, 'Manage transport') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" v-if="useImportExport" class="mb-3">
										<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="roleUserData.canExportImport">
											{{ FormMSG(2455, 'Can import/export') }}
										</b-form-checkbox>
									</b-col>
									<!-- <b-col md="4" v-if="useBudget" class="mb-3">
                                                <b-form-checkbox
                                                    size="lg"
                                                    class="pj-cb pb-1"
                                                    v-model="roleUserData.canViewDepartmentBudget"
                                                    @change="handleAccessLevel('canViewDepartmentBudget', $event, roleUserData, screenAccess)"
                                                >
                                                    {{ FormMSG(2690, 'View department budget') }}
                                                </b-form-checkbox>
                                            </b-col> -->
									<b-col md="4" v-if="useBudget" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canViewGlobalBudget"
											:value="true"
											:unchecked-value="false"
											class="pj-cb pb-1"
										>
											{{ FormMSG(24430, 'View global budget') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" v-if="useGreenTable" class="mb-3">
										<b-form-checkbox size="lg" v-model="roleUserData.canViewGreenTable" class="pj-cb pb-1">
											{{ FormMSG(245640, 'View Green Table') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useReporting" class="mb-3">
										<b-form-checkbox size="lg" v-model="roleUserData.canAccessGreenReporting" class="pj-cb pb-1">
											{{ FormMSG(24560, 'View Green reporting screens') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useCarbonRemoval" class="mb-3">
										<b-form-checkbox
											size="lg"
											v-model="roleUserData.canManageCarbonRemoval"
											:value="true"
											:unchecked-value="false"
											class="pj-cb pb-1"
										>
											{{ FormMSG(24480, 'Manage carbon removal') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" v-if="useWaste" class="mb-3">
										<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="roleUserData.canViewWasteScreens">
											{{ FormMSG(244005, 'View Waste screens') }}
										</b-form-checkbox>
									</b-col>
									<b-col md="4" v-if="useTipOfDay" class="mb-3">
										<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="roleUserData.canManageTipOfTheDay">
											{{ FormMSG(24405, 'Manage tip of the day') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
								<b-row style="padding-left: 30px">
									<b-col md="4" class="mb-3">
										<b-form-checkbox size="lg" v-model="roleUserData.canAccessConfig" class="pj-cb pb-1 mb-3">
											{{ FormMSG(246, 'Can access config') }}
										</b-form-checkbox>
									</b-col>
								</b-row>
							</fieldset>
							<fieldset v-if="needValidation" class="card-inside">
								<legend class="card-inside">
									{{ FormMSG(302, 'Validation rights') }}
								</legend>
								<div class="lh-22">
									<h6>
										<b>{{ FormMSG(303, 'Validation level for PO, Expenses and Timehseets') }}</b>
									</h6>
									<b-form-select v-model="roleUserData.validationLevel" :options="optionsForValidationLevel" id="valLevel" size="md">
									</b-form-select>
								</div>
							</fieldset>
							<fieldset v-if="useDoc" class="card-inside">
								<legend class="card-inside">
									{{ FormMSG(305, 'Onboarding validation role') }}
								</legend>
								<div class="lh-22">
									<b-row>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.gateKeeper">
												{{ FormMSG(266, 'Gate Keeper') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.productionAccountant">
												{{ FormMSG(261, 'Production Account') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.upm">
												{{ FormMSG(262, 'UPM') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.lineProducer">
												{{ FormMSG(263, 'Line Producer') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.studio">
												{{ FormMSG(265, 'Studio') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="roleUserData.canValidateI9">
												{{ FormMSG(270, 'Verify I-9') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
								</div>
							</fieldset>
							<fieldset v-if="usePoAndExp" class="card-inside">
								<legend class="card-inside">
									{{ FormMSG(355, 'Editing authorization') }}
								</legend>
								<div class="lh-22">
									<b-row>
										<b-col v-if="useExp" md="4">
											<b-form-checkbox v-model="roleUserData.canEditExpense" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(250, 'Can edit Expenses') }}
											</b-form-checkbox>
										</b-col>
										<b-col v-if="usePo" md="4">
											<b-form-checkbox v-model="roleUserData.canEditPo" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(255, 'Can edit PO') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
								</div>
							</fieldset>
							<fieldset class="card-inside" v-if="useFinance">
								<legend class="card-inside">
									{{ FormMSG(360, 'Accounting/Budget situation') }}
								</legend>
								<div class="lh-22">
									<b-row>
										<b-col md="4">
											<b-form-checkbox v-model="roleUserData.canLockBudget" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(361, 'Can lock/unlock initial budget') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox v-model="roleUserData.canModifyEstimatedFinalCost" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(362, 'Can modify EFC') }}
											</b-form-checkbox>
										</b-col>
										<b-col md="4">
											<b-form-checkbox v-model="roleUserData.canEditAndAddCode" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(363, 'Can edit/add codes') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
								</div>
							</fieldset>
							<fieldset v-if="showEntryForCrew" class="card-inside">
								<legend class="card-inside">
									{{ FormMSG(356, 'Entry for someone else') }}
								</legend>
								<div class="lh-22">
									<b-row>
										<b-col v-if="showEntryForCrewTimesheet" md="4">
											<b-form-checkbox v-model="userEntryLevel.timeSheets" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(251, 'Timesheets') }}
											</b-form-checkbox>
										</b-col>
										<b-col v-if="showEntryForCrewExpenses" md="4">
											<b-form-checkbox v-model="userEntryLevel.expenses" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(252, 'Expenses') }}
											</b-form-checkbox>
										</b-col>
										<b-col v-if="showEntryForCrewPo" md="4">
											<b-form-checkbox v-model="userEntryLevel.po" size="lg" class="pj-cb mb-3 pb-1">
												{{ FormMSG(253, 'PO') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<h6 v-show="userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets">
										<b>
											{{ FormMSG(357, 'Department selection') }}
										</b>
									</h6>
									<v-select
										v-show="userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets"
										class="style-chooser"
										@input="checkDepartment"
										multiple
										placeholder="Select a department"
										label="text"
										v-model="selectedDepartment"
										:options="mapDepartments"
									>
										<template v-slot:option="option">
											<b-form-checkbox v-model="option.selected" disabled size="lg" class="pj-cb mb-3 pb-1">
												{{ option.text }}
											</b-form-checkbox>
										</template>
									</v-select>
								</div>
							</fieldset>
						</b-col>
					</b-row>
				</div>
			</div>
			<div class="w-100 text-right" @click="handleSaveChanges">
				<b-button>{{ FormMSG(15654, 'Save modification') }}</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { getDepartments } from '@/cruds/department.crud';
import { mapGetters } from 'vuex';
import { getProject } from '@/cruds/project.crud';
import { getUserScreenAccess, actionAccessScreen, validate } from '@/cruds/users.crud';

export default {
	name: 'UserAuthorisation',
	mixins: [languageMessages, globalMixin],
	data() {
		return {
			roleUserData: {
				validationLevel: 0
			},
			userEntryLevel: {},
			allDepartement: [],
			selectedDepartment: [],
			projectAuthorisation: {},
			stateError: {
				msg: '',
				show: false
			},
			jobRequest: {
				targetProjectId: 0
			}
		};
	},
	async mounted() {
		await this.initData();
	},
	watch: {
		currentJobRequest: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.jobRequest = newVal;
				}
			},
			immediate: true,
			deep: true
		},
		allDepartements: {
			handler(newVal) {
				this.allDepartement = newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest',
			allDepartements: 'expenses/departmentsForSalary'
		}),
		userIsCreated() {
			return this.currentJobRequest.userCreated;
		},
		mapDepartments() {
			const departments = this.allDepartement.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			// return departments;
			var txt = this.FormMSG(22, 'Select a department');
			return [{ value: 0, text: txt }, ...departments];
			// return [{ value: null, text: "Select a department" }, ...departments];
		},
		useCallSheet() {
			return this.projectAuthorisation.useCallSheet;
		},
		useFinance() {
			return this.projectAuthorisation.useFinance;
		},
		useExp() {
			return this.projectAuthorisation.useExpense;
		},
		usePo() {
			return this.projectAuthorisation.usePurchaseOrder;
		},
		usePoAndExp() {
			return this.projectAuthorisation.useExpense || this.projectAuthorisation.usePurchaseOrder;
		},
		needValidation() {
			return this.projectAuthorisation.useExpense || this.projectAuthorisation.usePurchaseOrder || this.projectAuthorisation.useTimesheet;
		},
		useCarbonTemplate() {
			return this.projectAuthorisation.useCarbonTemplate;
		},
		useGreenTable() {
			return this.projectAuthorisation.useGreenTable;
		},
		useReporting() {
			return this.projectAuthorisation.useReporting;
		},
		useCarbonRemoval() {
			return this.projectAuthorisation.useCarbonRemoval;
		},
		useWaste() {
			return this.projectAuthorisation.useWaste;
		},
		useTipOfDay() {
			return this.projectAuthorisation.useTipOfDay;
		},
		useBudget() {
			return this.projectAuthorisation.useBudget;
		},
		useImportExport() {
			return this.projectAuthorisation.useImport || this.projectAuthorisation.useExport;
		},
		useTrans() {
			return this.projectAuthorisation.useTransport;
		},
		useLoc() {
			return this.projectAuthorisation.useLocations;
		},
		useAcc() {
			return this.projectAuthorisation.useAccommodation;
		},
		useDoc() {
			return this.projectAuthorisation.useDocument;
		},
		showEntryForCrew() {
			return this.projectAuthorisation.useEntryForCrew;
		},
		showEntryForCrewTimesheet() {
			return this.projectAuthorisation.useEntryForCrewTimesheet;
		},
		showEntryForCrewExpenses() {
			return this.projectAuthorisation.useEntryForCrewExpenses;
		},
		showEntryForCrewPo() {
			return this.projectAuthorisation.useEntryForCrewPo;
		},
		optionsForValidationLevel() {
			return this.FormMenu(1113);
		},
		useTask() {
			return this.projectAuthorisation.useTask;
		}
	},
	methods: {
		async initData() {
			this.$store.dispatch('expenses/getDepartmentsForSalaryJobRequest', +this.currentJobRequest.targetProjectId);
			await this.getUserAuth();
			await getProject(+this.currentJobRequest.targetProjectId).then((res) => {
				this.projectAuthorisation = res;
			});
		},
		async getUserAuth() {
			await getUserScreenAccess(+this.currentJobRequest.targetProjectId, +this.currentJobRequest.userId).then((res) => {
				this.roleUserData = res;
			});
		},
		checkDepartment(value, created) {
			this.allDepartement.forEach((item) => {
				item.selected = false;
			});
			value.forEach((item1) => {
				if (item1.value === -1) {
					this.selectedDepartment = [this.allDepartement[0]];
					this.allDepartement.forEach((item) => {
						item.selected = true;
					});
				} else {
					const found = this.allDepartement.find((item2) => item1.value === item2.value);
					if (found) {
						found.selected = true;
						if (created) {
							this.selectedDepartment.push(found);
						}
					}
				}
			});
			// this.handleUserEntryLevelChange();
		},
		async handleSaveChanges() {
			try {
				const replace = true;
				const projectId = +this.currentJobRequest.targetProjectId;
				const userId = +this.currentJobRequest.userId;
				await validate({
					userId,
					departmentNumber: +this.currentJobRequest.depAuthorization,
					levelTs: this.roleUserData.validationLevel,
					levelPo: this.roleUserData.validationLevel,
					levelExp: this.roleUserData.validationLevel,
					projectId,
					replace
				});
				await actionAccessScreen({ UpAccess: { ...this.roleUserData } }).then((res) => {
					if (res) {
						this.getUserAuth();
					}
				});
				this.createToastForMobile(this.FormMSG(214, 'Success'), this.FormMSG(5421, 'Update successfully!'));
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		}
	}
};
</script>
<style lang=""></style>
