<template>
	<error-container :error="error">
		<transition name="slide">
			<div class="pos-relative" id="detailsJobRequestContainer">
				<div :class="{ 'container-layout': $screen.width > 576, 'container-mobile': $screen.width <= 576 }">
					<div class="title" v-if="$screen.width <= 576">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(718, 'Job Request') }}
						</h1>
					</div>
					<div class="form">
						<b-row>
							<b-col sm="12" md="6" lg="6" xl="6">
								<div
									:class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]"
									class="cursor-pointer fs-24 fw-700"
									@click="goBack"
								>
									<component :is="getLucideIcon('ArrowLeft')" :size="22" :stroke-width="3" /> {{ FormMSG(18, 'Job Request') }} #{{
										currentJobRequest.jobRequestNumber
									}}
									- {{ currentJobRequest.fullName }}
								</div>
							</b-col>
							<b-col sm="12" md="6" lg="6" xl="6">
								<div class="d-flex justify-content-end">
									<b-button
										v-if="showViewAttachedDocument"
										variant="outline-primary"
										class="bg-color-white mr-2"
										@click="handleClickViewDocument"
									>
										<component :is="getLucideIcon('Eye')" :size="16" /> {{ FormMSG(45, 'View attached document') }}
									</b-button>
									<b-dropdown id="dropdown-action-selection" variant="outline-primary" :disabled="loadingActionSelection">
										<template #button-content>
											<b-spinner v-if="loadingActionSelection" small />
											{{ FormMSG(569, 'Change status') }}
										</template>
										<b-dropdown-item v-if="currentJobRequest.status !== 0" @click="handleClickChangeStatus(0)">
											<div class="d-flex text-color-rhapsody-in-blue">
												<div class="mr-2">
													<component :is="getLucideIcon('RotateCcw')" :size="18" />
												</div>
												<div>
													{{ FormMSG(815, 'Reset selection status') }}
												</div>
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="currentJobRequest.status !== 1" @click="handleClickChangeStatus(1)">
											<div class="d-flex text-color-rhapsody-in-blue">
												<div class="mr-2">
													<component :is="getLucideIcon('Send')" :size="18" />
												</div>
												<div>
													{{ FormMSG(715, 'Mars as PO SENT') }}
												</div>
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="currentJobRequest.status !== 2" @click="handleClickChangeStatus(2)">
											<div class="d-flex text-color-rhapsody-in-blue">
												<div class="mr-2">
													<component :is="getLucideIcon('Clock4')" :size="18" />
												</div>
												<div>
													{{ FormMSG(716, 'Mark as PO CONFIRMED') }}
												</div>
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="currentJobRequest.status !== 3" @click="handleClickChangeStatus(3)">
											<div class="d-flex text-color-rhapsody-in-blue">
												<div class="mr-2">
													<component :is="getLucideIcon('Check')" :size="18" />
												</div>
												<div>
													{{ FormMSG(717, 'Mark as CLOSED') }}
												</div>
											</div>
										</b-dropdown-item>
									</b-dropdown>

									<div class="ml-2 d-flex align-items-center">
										<div class="wrap-status">
											<div
												class="status fw-700"
												:class="{
													validated: currentJobRequest.status === 3,
													'not-submitted': currentJobRequest.status === 0,
													info: currentJobRequest.status === 1,
													pending: currentJobRequest.status === 2
												}"
											>
												<div class="text-center">
													{{ labelStatus }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>

						<b-row class="tab-budget mt-3">
							<b-col>
								<div class="pos-relative" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
									<b-tabs
										:value="jobRequestTabs"
										cards
										active-nav-item-class="font-weight-bold"
										active-tab-class="font-weight-bold"
										v-model="tabIndex"
									>
										<b-tab :title="FormMSG(106, 'Personal detail')">
											<b-card-text>
												<div>
													<profile-screen-to-tab
														for-job-request
														:external-user-id="+currentJobRequest.userId"
														hide-password-section
														hide-btn-save
														hide-action-change-picture
														hide-place-of-birth-zip
														hide-maiden-name
														hide-planning-color
														hide-info-identification
														hide-bank-account-owner-name
														hide-declaration-country
														hide-add-postal-address
														hide-info-health
														hide-personal-vehicle-section
														hide-info-other
													/>
												</div>
											</b-card-text>
										</b-tab>
										<b-tab :title="FormMSG(107, 'Company & Project detail')">
											<b-card-text>
												<div>
													<company-project-detail />
												</div>
											</b-card-text>
										</b-tab>
										<b-tab :title="FormMSG(108, 'Contract detail')">
											<b-card-text>
												<div>
													<contract-detail />
												</div>
											</b-card-text>
										</b-tab>
										<b-tab lazy :title="FormMSG(1111, 'Authorisation')">
											<UserAuthorisation />
										</b-tab>
										<b-tab :title="FormMSG(109, 'Send document')">
											<b-card-text>
												<div>
													<send-document-detail @send-document-detail:send-po-success="handleSendDocumentDetailSendPOSuccess" />
												</div>
											</b-card-text>
										</b-tab>
									</b-tabs>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</transition>
	</error-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import profileScreenToTab from '@/views/profileScreenToTab.vue';
import { mapActions, mapGetters } from 'vuex';
import { getUserById } from '@/cruds/users.crud';
import CompanyProjectDetail from '../components/CompanyProjectDetail.vue';
import UserAuthorisation from '../components/UserAuthorisation.vue';
import ContractDetail from '../components/ContractDetail.vue';
import SendDocumentDetail from '../components/SendDocumentDetail.vue';
import { changeStatusJobRequestList, getJobRequest } from '@/cruds/job-request.crud';
import { JOB_REQUEST_STATUS_MENU } from '../../../shared/constants';
import { getFileExtension } from '@/shared/helpers';

export default {
	name: 'JobRequestDetail',

	mixins: [languageMessages, globalMixin],

	components: {
		SendDocumentDetail,
		ContractDetail,
		profileScreenToTab,
		CompanyProjectDetail,
		UserAuthorisation
	},

	data() {
		return {
			error: {},
			user: {},

			jobRequestTabs: 0,
			tabIndex: 0,
			loadingActionSelection: false,
			stateError: {
				show: false,
				msg: ''
			},
			imageUrls: []
		};
	},

	// async created() {
	// 	await this.getUserById();
	// },

	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		labelStatus() {
			return this.GetTextFromMenuNumberAndMenuValue(JOB_REQUEST_STATUS_MENU, this.currentJobRequest.status);
		},

		showViewAttachedDocument() {
			return this.currentJobRequest.documentMemoDeal.length > 0;
		}
	},

	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),

		async handleSendDocumentDetailSendPOSuccess() {
			try {
				let result = await getJobRequest(this.currentJobRequest.id);

				this.setCurrentJobRequest(result);
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},

		handleClickViewDocument() {
			this.prepareImageUrls();
			this.showPicture();
		},

		showPicture(index) {
			const images = this.imageUrls.map((img) => {
				return {
					xid: img.xid,
					src: `${process.env.VUE_APP_GQL}/images/${img.xid}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img.xid,
					ext: getFileExtension(img.xid)
				};
			});

			this.$previewImages({
				images,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},

		prepareImageUrls() {
			const images = this.currentJobRequest.documentMemoDeal;
			if (images.length > 0) {
				this.imageUrl = `${process.env.VUE_APP_GQL}/images/${images[0]}`;
				images.map((option) => {
					const extension = getFileExtension(option);
					this.imageUrls.push({
						imgSrc: `${process.env.VUE_APP_GQL}/images/${option}`,
						src: `${process.env.VUE_APP_GQL}/images/${option}`,
						xid: option,
						extension
					});
				});
			}
		},

		async handleClickChangeStatus(status) {
			const action = async () => {
				try {
					this.loadingActionSelection = true;

					await changeStatusJobRequestList([+this.currentJobRequest.id], status);

					this.setCurrentJobRequest({
						...this.currentJobRequest,
						status
					});
					this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(621, 'Status changed successfully!'));

					this.loadingActionSelection = false;
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
					this.loadingActionSelection = false;
				}
			};

			this.confirmModal(action, this.FormMSG(587, 'Are you sure ?'));
		},

		async getUserById() {
			this.user = await getUserById(+this.currentJobRequest.userId);
		},

		goBack() {
			this.setCurrentJobRequest({});
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-budget {
	background-color: #ffffff;
	border-radius: 2px;
}
</style>
