<template>
	<div ref="container-contract-detail-job-request">
		<b-row>
			<b-col cols="12">
				<contract
					v-for="item in contracts"
					:key="item.id"
					:generateKey="+item.id"
					:contract-input="item"
					:accordion="accordion"
					:hide-send-package-btn="false"
					:show-pay-code="false"
					:fill-class-container="'container-layout'"
					:hide-btn-delete="true"
					:hide-btn-copy="true"
					:hide-btn-save-template="true"
					:from-job-request="true"
					@contract:show-body="handleContractShowBody"
					:useMemoDeal="true"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="4" offset-md="8" lg="4" offset-lg="8" xl="4" offset-xl="8">
				<div class="d-flex">
					<b-button
						block
						variant="primary"
						:disabled="disableBtnUserInProject || loadingAddUserInProject || showInfoUserAlreadyAddInProject"
						@click="handleClickAddUserInProject"
					>
						<b-spinner v-if="loadingAddUserInProject" small />
						{{ FormMSG(487, 'Add user in project') }}
					</b-button>
					<button
						v-if="disableBtnUserInProject"
						class="btn-transparent text-color-tree-sap ml-2"
						v-b-tooltip.hover
						:title="FormMSG(497, 'You must create or select a project and linked with the job request')"
					>
						<component :is="getLucideIcon('AlertTriangle')" :size="20" />
					</button>
					<button
						v-if="showInfoUserAlreadyAddInProject"
						class="btn-transparent text-color-blue-streak ml-2"
						v-b-tooltip.hover
						:title="FormMSG(1497, 'The user has already added in project')"
					>
						<component :is="getLucideIcon('Info')" :size="20" />
					</button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import contract from '@/views/users/contract';
import { contracts } from '@/cruds/contract.crud';
import { mapGetters, mapActions } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { addJobRequestOnProject } from '@/cruds/job-request.crud';
import _ from 'lodash';

export default {
	name: 'ContractDetail',

	mixins: [languageMessages, globalMixin],

	components: {
		contract
	},

	data() {
		return {
			contracts: [],
			accordion: {},

			loadingAddUserInProject: false,
			jobRequest: {
				targetProjectId: 0
			}
		};
	},

	watch: {
		currentJobRequest: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.jobRequest = newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		userIsCreated() {
			return this.currentJobRequest.userCreated;
		},

		showInfoUserAlreadyAddInProject() {
			return (!JSON.stringify(this.currentJobRequest) === '{}' && !_.isNil(this.currentJobRequest)) || this.currentJobRequest.userCreated === true;
		},

		disableBtnUserInProject() {
			return JSON.stringify(this.currentJobRequest) === '{}' || _.isNil(this.currentJobRequest) || this.currentJobRequest.targetProjectId === 0;
		}
	},

	async created() {
		this.$nextTick(async () => {
			await this.getContracts();
		});
	},

	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),

		async handleClickAddUserInProject() {
			const action = async () => {
				this.loadingAddUserInProject = true;
				const result = await addJobRequestOnProject(+this.currentJobRequest.id);

				this.setCurrentJobRequest(result);

				this.loadingAddUserInProject = false;
				this.createToastForMobile(this.FormMSG(451, 'Success'), this.FormMSG(452, 'User added successfully in project successfully!'));
			};

			this.confirmModal(action, this.FormMSG(453, 'Are you sure ?'));
		},
		handleContractShowBody(payload) {
			this.accordion = payload;
		},
		async getContracts() {
			const actionForLoader = async () => {
				this.contracts = await contracts(this.currentJobRequest.userId, this.currentJobRequest.id);
			};

			await this.showLoader(actionForLoader, 'container-contract-detail-job-request');
		}
	}
};
</script>

<style scoped></style>
