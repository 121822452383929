var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.userIsCreated
      ? _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-alert",
                      {
                        attrs: {
                          show: "",
                          dismissible: "",
                          variant: "warning",
                        },
                      },
                      [
                        _c(_vm.getLucideIcon("AlertTriangle"), {
                          tag: "component",
                          attrs: { size: 20 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                415,
                                "You must add user in project for configure user authorization"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", { staticClass: "content-card-collapsible" }, [
          _c("div", [
            _c(
              "div",
              [
                _vm.stateError.show
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-alert",
                              {
                                attrs: {
                                  variant: "danger",
                                  show: "",
                                  dismissible: "",
                                },
                                on: {
                                  dismissed: function ($event) {
                                    _vm.stateError.show = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.stateError.msg) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", md: "12" } }, [
                      _c(
                        "fieldset",
                        { staticClass: "card-inside" },
                        [
                          _c("legend", { staticClass: "card-inside" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(301, "Application rights")) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                          ]),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "pj-cb pb-1",
                                      attrs: {
                                        size: "lg",
                                        value: true,
                                        "unchecked-value": false,
                                      },
                                      model: {
                                        value:
                                          _vm.roleUserData.canManageCrewList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.roleUserData,
                                            "canManageCrewList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "roleUserData.canManageCrewList",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              2970,
                                              "Manage crew list"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm.useDoc
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageDocuments,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageDocuments",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageDocuments",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2971,
                                                  "Manage documents"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useDoc
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageDocumentPackage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageDocumentPackage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageDocumentPackage",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2440,
                                                  "Manage document package"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useCallSheet
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageCallSheets,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageCallSheets",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageCallSheets",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2450,
                                                  "Manage call sheets"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm.useAcc
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageAccommodations,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageAccommodations",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageAccommodations",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2973,
                                                  "Manage accomodations"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useLoc
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageLocations,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageLocations",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageLocations",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2449,
                                                  "Manage locations"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useTrans
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageTransport,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageTransport",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageTransport",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  20449,
                                                  "Manage transport"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm.useImportExport
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData.canExportImport,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canExportImport",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canExportImport",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  2455,
                                                  "Can import/export"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useBudget
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canViewGlobalBudget,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canViewGlobalBudget",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canViewGlobalBudget",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  24430,
                                                  "View global budget"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm.useGreenTable
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canViewGreenTable,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canViewGreenTable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canViewGreenTable",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  245640,
                                                  "View Green Table"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useReporting
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canAccessGreenReporting,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canAccessGreenReporting",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canAccessGreenReporting",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  24560,
                                                  "View Green reporting screens"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useCarbonRemoval
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageCarbonRemoval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageCarbonRemoval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageCarbonRemoval",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  24480,
                                                  "Manage carbon removal"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _vm.useWaste
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canViewWasteScreens,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canViewWasteScreens",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canViewWasteScreens",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  244005,
                                                  "View Waste screens"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.useTipOfDay
                                ? _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { md: "4" } },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: { size: "lg" },
                                          model: {
                                            value:
                                              _vm.roleUserData
                                                .canManageTipOfTheDay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.roleUserData,
                                                "canManageTipOfTheDay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "roleUserData.canManageTipOfTheDay",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  24405,
                                                  "Manage tip of the day"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "padding-left": "30px" } },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-3", attrs: { md: "4" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "pj-cb pb-1 mb-3",
                                      attrs: { size: "lg" },
                                      model: {
                                        value: _vm.roleUserData.canAccessConfig,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.roleUserData,
                                            "canAccessConfig",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "roleUserData.canAccessConfig",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              246,
                                              "Can access config"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.needValidation
                        ? _c("fieldset", { staticClass: "card-inside" }, [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(302, "Validation rights")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lh-22" },
                              [
                                _c("h6", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          303,
                                          "Validation level for PO, Expenses and Timehseets"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("b-form-select", {
                                  attrs: {
                                    options: _vm.optionsForValidationLevel,
                                    id: "valLevel",
                                    size: "md",
                                  },
                                  model: {
                                    value: _vm.roleUserData.validationLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.roleUserData,
                                        "validationLevel",
                                        $$v
                                      )
                                    },
                                    expression: "roleUserData.validationLevel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.useDoc
                        ? _c("fieldset", { staticClass: "card-inside" }, [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      305,
                                      "Onboarding validation role"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lh-22" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData.gateKeeper,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "gateKeeper",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.gateKeeper",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    266,
                                                    "Gate Keeper"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .productionAccountant,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "productionAccountant",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.productionAccountant",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    261,
                                                    "Production Account"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value: _vm.roleUserData.upm,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "upm",
                                                  $$v
                                                )
                                              },
                                              expression: "roleUserData.upm",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(262, "UPM")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData.lineProducer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "lineProducer",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.lineProducer",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    263,
                                                    "Line Producer"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value: _vm.roleUserData.studio,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "studio",
                                                  $$v
                                                )
                                              },
                                              expression: "roleUserData.studio",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(265, "Studio")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData.canValidateI9,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canValidateI9",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canValidateI9",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(270, "Verify I-9")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.usePoAndExp
                        ? _c("fieldset", { staticClass: "card-inside" }, [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(355, "Editing authorization")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lh-22" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _vm.useExp
                                      ? _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: { size: "lg" },
                                                model: {
                                                  value:
                                                    _vm.roleUserData
                                                      .canEditExpense,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.roleUserData,
                                                      "canEditExpense",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "roleUserData.canEditExpense",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        250,
                                                        "Can edit Expenses"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.usePo
                                      ? _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: { size: "lg" },
                                                model: {
                                                  value:
                                                    _vm.roleUserData.canEditPo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.roleUserData,
                                                      "canEditPo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "roleUserData.canEditPo",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        255,
                                                        "Can edit PO"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.useFinance
                        ? _c("fieldset", { staticClass: "card-inside" }, [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      360,
                                      "Accounting/Budget situation"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lh-22" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData.canLockBudget,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canLockBudget",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canLockBudget",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    361,
                                                    "Can lock/unlock initial budget"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canModifyEstimatedFinalCost,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canModifyEstimatedFinalCost",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canModifyEstimatedFinalCost",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    362,
                                                    "Can modify EFC"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "4" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb mb-3 pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.roleUserData
                                                  .canEditAndAddCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.roleUserData,
                                                  "canEditAndAddCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "roleUserData.canEditAndAddCode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    363,
                                                    "Can edit/add codes"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.showEntryForCrew
                        ? _c("fieldset", { staticClass: "card-inside" }, [
                            _c("legend", { staticClass: "card-inside" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(356, "Entry for someone else")
                                  ) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lh-22" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _vm.showEntryForCrewTimesheet
                                      ? _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: { size: "lg" },
                                                model: {
                                                  value:
                                                    _vm.userEntryLevel
                                                      .timeSheets,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userEntryLevel,
                                                      "timeSheets",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userEntryLevel.timeSheets",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        251,
                                                        "Timesheets"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showEntryForCrewExpenses
                                      ? _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: { size: "lg" },
                                                model: {
                                                  value:
                                                    _vm.userEntryLevel.expenses,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userEntryLevel,
                                                      "expenses",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userEntryLevel.expenses",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        252,
                                                        "Expenses"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showEntryForCrewPo
                                      ? _c(
                                          "b-col",
                                          { attrs: { md: "4" } },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: { size: "lg" },
                                                model: {
                                                  value: _vm.userEntryLevel.po,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userEntryLevel,
                                                      "po",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userEntryLevel.po",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(253, "PO")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "h6",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.userEntryLevel.po ||
                                          _vm.userEntryLevel.expenses ||
                                          _vm.userEntryLevel.timeSheets,
                                        expression:
                                          "userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets",
                                      },
                                    ],
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              357,
                                              "Department selection"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("v-select", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.userEntryLevel.po ||
                                        _vm.userEntryLevel.expenses ||
                                        _vm.userEntryLevel.timeSheets,
                                      expression:
                                        "userEntryLevel.po || userEntryLevel.expenses || userEntryLevel.timeSheets",
                                    },
                                  ],
                                  staticClass: "style-chooser",
                                  attrs: {
                                    multiple: "",
                                    placeholder: "Select a department",
                                    label: "text",
                                    options: _vm.mapDepartments,
                                  },
                                  on: { input: _vm.checkDepartment },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "option",
                                        fn: function (option) {
                                          return [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "pj-cb mb-3 pb-1",
                                                attrs: {
                                                  disabled: "",
                                                  size: "lg",
                                                },
                                                model: {
                                                  value: option.selected,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      option,
                                                      "selected",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "option.selected",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(option.text) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    791507781
                                  ),
                                  model: {
                                    value: _vm.selectedDepartment,
                                    callback: function ($$v) {
                                      _vm.selectedDepartment = $$v
                                    },
                                    expression: "selectedDepartment",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              on: { click: _vm.handleSaveChanges },
            },
            [
              _c("b-button", [
                _vm._v(_vm._s(_vm.FormMSG(15654, "Save modification"))),
              ]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }