var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "confirm-send-po-dialog",
        title: _vm.FormMSG(51, "Confirm"),
        "header-class": "header-class-modal-doc-package",
        size: "sm",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary", block: "" },
                              on: { click: cancel },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(53, "Cancel")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary", block: "" },
                              on: { click: ok },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(52, "Send")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "fs-14 text-color-rhapsody-in-blue mb-4" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.FormMSG(54, "Please select the recipient:")) +
                "\n\t\t"
            ),
          ]),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(56, "Share PO to:") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.FormMSG(857, "Enter email"),
                          state: _vm.getStateValidationField("contactEmail"),
                        },
                        model: {
                          value: _vm.$v.contactEmail.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.contactEmail, "$model", $$v)
                          },
                          expression: "$v.contactEmail.$model",
                        },
                      }),
                      !_vm.$v.contactEmail.required &&
                      _vm.getStateValidationField("contactEmail") !== null
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(859, "Please enter email")) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                      !_vm.$v.contactEmail.email &&
                      _vm.getStateValidationField("contactEmail") !== null
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    860,
                                    "Please enter an email valid"
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }