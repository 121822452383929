var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(
          "\n\t\t" + _vm._s(_vm.FormMSG(5, "Identification Numbers")) + "\n\t"
        ),
      ]),
      _vm.showInfoProjectAlreadyCreated && !_vm.userIsCreated
        ? _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "info", dismissible: "" } },
                    [
                      _c(_vm.getLucideIcon("Info"), {
                        tag: "component",
                        attrs: { size: 16 },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.FormMSG(
                              789,
                              "A project is already linked with the job request."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showInfoProjectAlreadyCreated && _vm.userIsCreated
        ? _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "info", dismissible: "" } },
                    [
                      _c(_vm.getLucideIcon("Info"), {
                        tag: "component",
                        attrs: { size: 16 },
                      }),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              792,
                              "A project is already linked with the job request and the user is linked."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.stateError.show
        ? _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c(
                "b-col",
                [
                  _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.stateError.msg) + "\n\t\t\t"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("custom-label-mandatory", {
                            attrs: {
                              label: _vm.FormMSG(
                                45,
                                "Production company (Legal entity)"
                              ),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("supplier-selector", {
                    ref: "selectorCompany",
                    attrs: {
                      label: "",
                      "show-map": false,
                      title: _vm.titleSupllierSelector,
                      placeholder: _vm.FormMSG(
                        105,
                        "Enter your legal entity name"
                      ),
                      addlabel: _vm.FormMSG(111, "Add"),
                      "update-label": _vm.FormMSG(112, "Update"),
                      type: "projectAdmin",
                      "use-new-design": "",
                      "col-selector": 6,
                      "supplier-id": _vm.$v.jobRequest.supplierId.$model,
                    },
                    on: {
                      change: _vm.handleAdminOwnerChange,
                      "supplier:unselected": _vm.onAdminOwnerUnselected,
                    },
                  }),
                  _vm.$v.jobRequest.supplierId.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                472,
                                "Please choice or create the legal entity"
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(113, "Contact name") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.FormMSG(114, "Enter contact name"),
                    },
                    model: {
                      value: _vm.jobRequest.supplier.contactName,
                      callback: function ($$v) {
                        _vm.$set(_vm.jobRequest.supplier, "contactName", $$v)
                      },
                      expression: "jobRequest.supplier.contactName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(115, "Contact email") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.FormMSG(116, "Enter contact email"),
                    },
                    model: {
                      value: _vm.jobRequest.supplier.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.jobRequest.supplier, "email", $$v)
                      },
                      expression: "jobRequest.supplier.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(117, "Contact phone") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.FormMSG(118, "Enter contact phone"),
                    },
                    model: {
                      value: _vm.jobRequest.supplier.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.jobRequest.supplier, "phone", $$v)
                      },
                      expression: "jobRequest.supplier.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(
          "\n\t\t" + _vm._s(_vm.FormMSG(119, "Project information")) + "\n\t"
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("custom-label-mandatory", {
                            attrs: { label: _vm.FormMSG(289, "Project name") },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: _vm.FormMSG(120, "Enter project name"),
                      state: _vm.getStateValidationField(
                        "jobRequest.projectName"
                      ),
                      disabled: _vm.userIsCreated,
                    },
                    model: {
                      value: _vm.$v.jobRequest.projectName.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.jobRequest.projectName, "$model", $$v)
                      },
                      expression: "$v.jobRequest.projectName.$model",
                    },
                  }),
                  _vm.$v.jobRequest.projectName.$error &&
                  _vm.getStateValidationField("jobRequest.projectName") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(473, "Please enter the project name")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("custom-label-mandatory", {
                            attrs: {
                              label: _vm.FormMSG(619, "Production type"),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.mapTgsproductionType,
                      state: _vm.getStateValidationField(
                        "jobRequest.productionType"
                      ),
                      disabled: _vm.userIsCreated,
                    },
                    model: {
                      value: _vm.$v.jobRequest.productionType.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.jobRequest.productionType,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.jobRequest.productionType.$model",
                    },
                  }),
                  _vm.$v.jobRequest.productionType.$error &&
                  _vm.getStateValidationField("jobRequest.productionType") !==
                    null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                474,
                                "Please choice the production type"
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c("b-form-group", {
                attrs: { label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ ariaDescribedby }) {
                      return [
                        _c(
                          "b-form-radio-group",
                          {
                            attrs: {
                              id: "radio-group-2",
                              "aria-describedby": ariaDescribedby,
                              name: "projectChoiceCompanyDetail",
                            },
                            model: {
                              value: _vm.projectChoice,
                              callback: function ($$v) {
                                _vm.projectChoice = $$v
                              },
                              expression: "projectChoice",
                            },
                          },
                          [
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  value: 1,
                                  disabled: _vm.userIsCreated,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(125, "Create as new project")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  value: 2,
                                  disabled: _vm.userIsCreated,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(126, "Existing project"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-collapse", { attrs: { visible: _vm.projectChoice === 1 } }, [
        _vm.projectChoice === 1
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(201, "License "),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2170659148
                            ),
                          },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid":
                                  _vm.$v.newProject.licenseId.$error,
                              },
                              attrs: {
                                label: "content",
                                options: _vm.licenses,
                                reduce: (option) => +option.id,
                                placeholder: _vm.FormMSG(
                                  189,
                                  "Please select ..."
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.licenseId.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.licenseId,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.licenseId.$model",
                              },
                            }),
                            _vm.$v.newProject.licenseId.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          475,
                                          "Please select license"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(215, "Production"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3157957169
                            ),
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                placeholder: _vm.FormMSG(
                                  190,
                                  "Enter production"
                                ),
                                state: _vm.getStateValidationField(
                                  "newProject.production"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.production.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.production,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.production.$model",
                              },
                            }),
                            _vm.$v.newProject.production.$error &&
                            _vm.getStateValidationField(
                              "newProject.production"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          476,
                                          "Please insert the production"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(216, "Director"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2444971105
                            ),
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                placeholder: _vm.FormMSG(191, "Enter director"),
                                state: _vm.getStateValidationField(
                                  "newProject.director"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.director.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.director,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.director.$model",
                              },
                            }),
                            _vm.$v.newProject.director.$error &&
                            _vm.getStateValidationField(
                              "newProject.director"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          477,
                                          "Please insert the director"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(217, "Language"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2042917122
                            ),
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionsForLanguages,
                                id: "optionsForLanguages",
                                state: _vm.getStateValidationField(
                                  "newProject.language"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.language.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.language,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.language.$model",
                              },
                            }),
                            _vm.$v.newProject.language.$error &&
                            _vm.getStateValidationField(
                              "newProject.language"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          487,
                                          "Please select a language"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            218,
                                            "Regional settings"
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3009845519
                            ),
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.regionalSettingOptions,
                                id: "regionalSettingOptions",
                                state: _vm.getStateValidationField(
                                  "newProject.regionalSetting"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.regionalSetting.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.regionalSetting,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.newProject.regionalSetting.$model",
                              },
                            }),
                            _vm.$v.newProject.regionalSetting.$error &&
                            _vm.getStateValidationField(
                              "newProject.regionalSetting"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          478,
                                          "Please select regional settings"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(219, "Time zone"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2093365045
                            ),
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                state: _vm.getStateValidationField(
                                  "newProject.timeZone"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.timeZone.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.timeZone,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.timeZone.$model",
                              },
                            }),
                            _vm.$v.newProject.timeZone.$error &&
                            _vm.getStateValidationField(
                              "newProject.timeZone"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          479,
                                          "Please enter Time zone"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(220, "Currency"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1182327251
                            ),
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "currency",
                                options: _vm.mapCurrencies,
                                state: _vm.getStateValidationField(
                                  "newProject.currency"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.currency.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.currency,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.currency.$model",
                              },
                            }),
                            _vm.$v.newProject.currency.$error &&
                            _vm.getStateValidationField(
                              "newProject.currency"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          480,
                                          "Please select currency"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            221,
                                            "Distance unit"
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              27186288
                            ),
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "distanceUnit",
                                options: _vm.mapDistacneUnits,
                                state: _vm.getStateValidationField(
                                  "newProject.distanceUnit"
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.$v.newProject.distanceUnit.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.newProject.distanceUnit,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.newProject.distanceUnit.$model",
                              },
                            }),
                            _vm.$v.newProject.distanceUnit.$error &&
                            _vm.getStateValidationField(
                              "newProject.distanceUnit"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          476,
                                          "Please select distance unit"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(235, "Start date"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              847499780
                            ),
                          },
                          [
                            _c("v-date-picker", {
                              attrs: {
                                value: _vm.$v.newProject.startDate.$model,
                                masks: { input: "DD/MM/YYYY" },
                              },
                              on: { input: _vm.handleInputStartDate },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({
                                      inputValue,
                                      togglePopover,
                                    }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "bg-white",
                                              attrs: {
                                                value: inputValue,
                                                readonly: "",
                                                disabled: _vm.userIsCreated,
                                              },
                                              on: { click: togglePopover },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    on: {
                                                      click: togglePopover,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Calendar"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#06263E",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2368536669
                              ),
                            }),
                            _vm.$v.newProject.startDate.$error &&
                            _vm.getStateValidationField(
                              "newProject.startDate"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          498,
                                          "Please insert start date"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(236, "End date"),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2333151688
                            ),
                          },
                          [
                            _c("v-date-picker", {
                              attrs: {
                                value: _vm.$v.newProject.endDate.$model,
                                masks: { input: "DD/MM/YYYY" },
                                "min-date": _vm.newProject.startDate,
                              },
                              on: { input: _vm.handleInputEndDate },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({
                                      inputValue,
                                      togglePopover,
                                    }) {
                                      return [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "bg-white",
                                              attrs: {
                                                value: inputValue,
                                                readonly: "",
                                                disabled: _vm.userIsCreated,
                                              },
                                              on: { click: togglePopover },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    on: {
                                                      click: togglePopover,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Calendar"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#06263E",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2368536669
                              ),
                            }),
                            _vm.$v.newProject.endDate.$error &&
                            _vm.getStateValidationField(
                              "newProject.endDate"
                            ) !== null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          499,
                                          "Please insert end date"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(241, "Carbon calculator"),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "carbonCalculator",
                                options: _vm.mapCarbonModels,
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.newProject.carbonModel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.newProject, "carbonModel", $$v)
                                },
                                expression: "newProject.carbonModel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: _vm.FormMSG(242, "Certification") },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "greenReporting",
                                options: _vm.mapGreenReporting,
                                disabled: _vm.userIsCreated,
                              },
                              model: {
                                value: _vm.newProject.greenReporting,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newProject,
                                    "greenReporting",
                                    $$v
                                  )
                                },
                                expression: "newProject.greenReporting",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("b-collapse", { attrs: { visible: _vm.projectChoice === 2 } }, [
        _vm.projectChoice === 2
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("custom-label-mandatory", {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            250,
                                            "Project selection"
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2183821830
                            ),
                          },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid":
                                  _vm.$v.projectSelected.$error &&
                                  _vm.getStateValidationField(
                                    "projectSelected"
                                  ),
                              },
                              attrs: {
                                options: _vm.listProject,
                                reduce: (option) => +option.id,
                                label: "titre",
                                placeholder: _vm.FormMSG(
                                  189,
                                  "Please select ..."
                                ),
                                disabled: _vm.userIsCreated,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function ({ titre, license }) {
                                      return [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c(
                                            "div",
                                            { staticClass: "fw-700 mr-2" },
                                            [_vm._v(_vm._s(titre))]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(license.companyName) +
                                                ")"
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2441972106
                              ),
                              model: {
                                value: _vm.$v.projectSelected.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.projectSelected,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.projectSelected.$model",
                              },
                            }),
                            _vm.$v.projectSelected.$error &&
                            _vm.getStateValidationField("projectSelected") !==
                              null
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          589,
                                          "Please select a project"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "3", offset: "9" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    block: "",
                    disabled: _vm.loadingSubmitCreateProject,
                  },
                  on: { click: _vm.handleClickBtnSave },
                },
                [
                  _vm.loadingSubmitCreateProject
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.saveLabel) + "\n\t\t\t"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }