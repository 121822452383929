var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.stateError.show
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { show: "", dismissible: "", variant: "danger" },
                      on: { dismissed: _vm.handleDismissed },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.stateError.msg) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.userIsCreated
        ? _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { show: "", dismissible: "", variant: "warning" },
                    },
                    [
                      _c(_vm.getLucideIcon("AlertTriangle"), {
                        tag: "component",
                        attrs: { size: 20 },
                      }),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              415,
                              "You must add user in project for send a PO"
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "sub-title" }, [
        _vm._v(
          "\n\t\t" + _vm._s(_vm.FormMSG(5, "PO for Job Request")) + "\n\t"
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "outline-primary",
                    block: "",
                    disabled: !_vm.userIsCreated || _vm.isLoadingReview,
                  },
                  on: { click: _vm.handleClickReviewPo },
                },
                [
                  _vm.isLoadingReview
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(25, "Review PO")) +
                      "\n\t\t\t"
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    block: "",
                    disabled: !_vm.userIsCreated || _vm.isLoadingDownload,
                  },
                  on: { click: _vm.handleClickSendPo },
                },
                [
                  _vm.isLoadingDownload
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(26, "Send PO")) +
                      "\n\t\t\t"
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "sub-title mt-3 mb-3" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.FormMSG(27, "Send document to created user")) +
            "\n\t"
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c(
                "b-form-radio",
                {
                  attrs: {
                    disabled: !_vm.userIsCreated || !_vm.canSendDocument,
                    name: "existingDocument",
                    value: true,
                  },
                  on: { change: _vm.handleChangeNewDocumentRadio },
                  model: {
                    value: _vm.existingDocument,
                    callback: function ($$v) {
                      _vm.existingDocument = $$v
                    },
                    expression: "existingDocument",
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(58, "Existing document")))]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c(
                "b-form-radio",
                {
                  attrs: {
                    disabled: !_vm.userIsCreated || !_vm.canSendDocument,
                    name: "existingDocument",
                    value: false,
                  },
                  on: { change: _vm.handleChangeNewDocumentRadio },
                  model: {
                    value: _vm.existingDocument,
                    callback: function ($$v) {
                      _vm.existingDocument = $$v
                    },
                    expression: "existingDocument",
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(59, "Create new document")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-collapse", { attrs: { visible: _vm.existingDocument } }, [
        _vm.existingDocument
          ? _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(69, "Document selection"),
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.documents,
                                label: "name",
                                placeholder: _vm.FormMSG(
                                  574,
                                  "Please select ..."
                                ),
                                reduce: (option) => +option.id,
                                disabled:
                                  !_vm.userIsCreated || !_vm.canSendDocument,
                              },
                              model: {
                                value: _vm.documentSelected,
                                callback: function ($$v) {
                                  _vm.documentSelected = $$v
                                },
                                expression: "documentSelected",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticStyle: { "padding-top": "20px" },
                        attrs: { sm: "12", md: "2", lg: "2", xl: "2" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "outline-primary",
                              block: "",
                              disabled:
                                !_vm.userIsCreated || _vm.loadingSendDocument,
                            },
                            on: { click: _vm.handleSendDocument },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(70, "Send")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("b-collapse", { attrs: { visible: !_vm.existingDocument } }, [
        !_vm.existingDocument
          ? _c(
              "div",
              [
                _c("document-details", {
                  attrs: {
                    "external-document-id": _vm.documentId,
                    "external-project-id":
                      _vm.currentJobRequest.targetProjectId,
                    "use-external-document-id": true,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("script-export-modal", {
        attrs: {
          items: _vm.scripts,
          custom: _vm.customReport,
          "export-type": "jobrequest",
        },
        on: {
          "script-export-modal:loading": _vm.handleScriptExportModalLoading,
          "script-export-modal:closed": _vm.handleScriptExportModalClosed,
        },
        model: {
          value: _vm.isScriptExpenseReportOpen,
          callback: function ($$v) {
            _vm.isScriptExpenseReportOpen = $$v
          },
          expression: "isScriptExpenseReportOpen",
        },
      }),
      _c("confirm-send-po-dialog", {
        attrs: { open: _vm.showConfirmSendPoDialog },
        on: {
          "confirm-send-po-dialog:send": _vm.handleConfirmSendPoDialogSend,
          "confirm-send-po-dialog:close": _vm.handleConfirmSendPoDialogClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }