<template>
	<div>
		<b-row v-if="stateError.show">
			<b-col>
				<b-alert show dismissible variant="danger" @dismissed="handleDismissed">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row class="mt-2 mb-2" v-if="!userIsCreated">
			<b-col>
				<b-alert show dismissible variant="warning">
					<component :is="getLucideIcon('AlertTriangle')" :size="20" />
					{{ FormMSG(415, 'You must add user in project for send a PO') }}
				</b-alert>
			</b-col>
		</b-row>
		<div class="sub-title">
			{{ FormMSG(5, 'PO for Job Request') }}
		</div>
		<b-row class="mt-3">
			<b-col sm="12" md="2" lg="2" xl="2">
				<b-button variant="outline-primary" block :disabled="!userIsCreated || isLoadingReview" @click="handleClickReviewPo">
					<b-spinner v-if="isLoadingReview" small />
					{{ FormMSG(25, 'Review PO') }}
				</b-button>
			</b-col>
			<b-col sm="12" md="2" lg="2" xl="2">
				<b-button variant="primary" block :disabled="!userIsCreated || isLoadingDownload" @click="handleClickSendPo">
					<b-spinner v-if="isLoadingDownload" small />
					{{ FormMSG(26, 'Send PO') }}
				</b-button>
			</b-col>
		</b-row>

		<div class="sub-title mt-3 mb-3">
			{{ FormMSG(27, 'Send document to created user') }}
		</div>
		<b-row class="mb-3">
			<b-col sm="12" md="2" lg="2" xl="2">
				<b-form-radio
					v-model="existingDocument"
					:disabled="!userIsCreated || !canSendDocument"
					name="existingDocument"
					:value="true"
					@change="handleChangeNewDocumentRadio"
					>{{ FormMSG(58, 'Existing document') }}</b-form-radio
				>
			</b-col>
			<b-col sm="12" md="2" lg="2" xl="2">
				<b-form-radio
					v-model="existingDocument"
					:disabled="!userIsCreated || !canSendDocument"
					name="existingDocument"
					:value="false"
					@change="handleChangeNewDocumentRadio"
					>{{ FormMSG(59, 'Create new document') }}</b-form-radio
				>
			</b-col>
		</b-row>
		<b-collapse :visible="existingDocument">
			<div v-if="existingDocument">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(69, 'Document selection')">
							<v-select
								v-model="documentSelected"
								:options="documents"
								label="name"
								:placeholder="FormMSG(574, 'Please select ...')"
								:reduce="(option) => +option.id"
								:disabled="!userIsCreated || !canSendDocument"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2" style="padding-top: 20px">
						<b-button variant="outline-primary" block :disabled="!userIsCreated || loadingSendDocument" @click="handleSendDocument">
							{{ FormMSG(70, 'Send') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-collapse>
		<b-collapse :visible="!existingDocument">
			<div v-if="!existingDocument">
				<document-details
					:external-document-id="documentId"
					:external-project-id="currentJobRequest.targetProjectId"
					:use-external-document-id="true"
				/>
			</div>
		</b-collapse>

		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="jobrequest"
			@script-export-modal:loading="handleScriptExportModalLoading"
			@script-export-modal:closed="handleScriptExportModalClosed"
		/>

		<confirm-send-po-dialog
			:open="showConfirmSendPoDialog"
			@confirm-send-po-dialog:send="handleConfirmSendPoDialogSend"
			@confirm-send-po-dialog:close="handleConfirmSendPoDialogClose"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ConfirmSendPoDialog from './ConfirmSendPoDialog.vue';
import DocumentDetails from '@/views/documents/documentDetails.vue';
import { getDocumentList, newDocument, deleteDocument } from '@/modules/document-package/cruds/document.crud';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment/moment';
import _ from 'lodash';
import { getScripts } from '@/cruds/script.crud';
import { FILENAME_REPORT } from '../../../shared/constants';
import { jobRequestGridReportFromTo } from '../../../cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal.vue';
import { publishDocument, selectUserForDelivery } from '../../../cruds/document.crud';
import { contracts } from '@/cruds/contract.crud';

export default {
	name: 'SendDocumentDetail',
	components: { DocumentDetails, ConfirmSendPoDialog, ScriptExportModal },

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			loadingSendDocument: false,
			existingDocument: true,
			showConfirmSendPoDialog: false,
			documentId: 0,
			documents: [],
			documentSelected: null,
			stateError: {
				msg: '',
				show: false
			},

			scripts: [],
			warning: '',
			isLoadingDownload: false,
			isLoadingReview: false,
			isScriptExpenseReportOpen: false,
			customReport: {}
		};
	},

	async created() {
		await this.getDocumentList();
	},

	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		canSendDocument() {
			return this.currentJobRequest.status === 2;
		},

		userIsCreated() {
			return this.currentJobRequest.userCreated;
		}
	},

	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),

		async handleSendDocument() {
			try {
				this.loadingSendDocument = true;
				const contractList = await contracts(this.currentJobRequest.userId, this.currentJobRequest.id);
				let deliveryDetail = [];

				for (let i = 0; i < contractList.length; i++) {
					deliveryDetail.push({
						contractId: +contractList[i].id,
						userId: this.currentJobRequest.userId,
						projectId: this.currentJobRequest.targetProjectId
					});
				}

				await selectUserForDelivery(this.documentSelected, deliveryDetail);
				await publishDocument(this.documentSelected);

				this.createToastForMobile(this.FormMSG(527, 'Success'), this.FormMSG(1547, 'The document has sended successfully!'));
				this.loadingSendDocument = false;
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},

		async handleChangeNewDocumentRadio(payload) {
			if (!payload) {
				await this.newDocument();
			} else {
				await this.deleteDocument();
			}
		},

		async deleteDocument() {
			try {
				if (this.documentId > 0) {
					await deleteDocument(this.documentId);
					this.documentId = null;
				}
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.msg = true;
			}
		},

		newDocument() {
			newDocument({
				projectId: this.currentJobRequest.targetProjectId,
				name: this.FormMSG(847, 'NEW DOCUMENT'),
				availableOnline: true
			})
				.then((result) => {
					// reload the Data
					// save new to newDoc
					this.documentId = +result.id;
				})
				.catch((err) => {
					this.stateError.msg = this.resolveGqlError(err);
					this.stateError.show = true;
				});
		},

		handleScriptExportModalClosed() {
			this.isScriptExpenseReportOpen = false;
			this.isLoadingDownload = false;
			this.isLoadingReview = false;
		},

		handleScriptExportModalLoading(payload) {
			if (payload) {
				this.isLoadingDownload = false;
				this.isLoadingReview = false;

				this.emitEventSendPOSuccess();
			}
		},

		handleDismissed() {
			this.stateError.msg = '';
			this.stateError.show = false;
		},

		async handleClickReviewPo() {
			await this.sendOrReviewPo(false, null, 'isLoadingReview');

			this.emitEventSendPOSuccess();
		},

		async handleConfirmSendPoDialogSend(payload) {
			await this.sendOrReviewPo(true, payload, 'isLoadingDownload');

			this.emitEventSendPOSuccess();
		},

		emitEventSendPOSuccess() {
			this.$emit('send-document-detail:send-po-success');
		},

		async sendOrReviewPo(sendEmail, contactEmail, loadingField) {
			this[loadingField] = true;
			this.warning = '';
			this.scripts = await getScripts(null, this.currentJobRequest.projectId, 18, true);
			const id = this.currentJobRequest.id;

			if (this.scripts.length === 0) {
				this.stateError.msg = this.FormMSG(987, 'You must import a script template');
				this.stateError.show = true;
				this[loadingField] = false;
			} else if (this.scripts.length === 1) {
				await this.manageReport(
					id,
					{
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: 'pdf'
					},
					sendEmail,
					contactEmail
				)
					.catch((error) => {
						this.stateError.msg = this.resolveGqlError(error);
						this.stateError.show = true;
					})
					.finally(() => {
						this[loadingField] = false;
					});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					sendEmail,
					jobRequestId: +this.currentJobRequest.id,
					contactEmail
				};
			}
		},

		async manageReport(jobRequestId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = true, contactEmail = null) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_JOB_REQUEST, script.id, script.fileName);

			await jobRequestGridReportFromTo(jobRequestId, script.id, sendEmail, contactEmail)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.pdf');
					}
				})
				.catch((error) => {
					this.stateError.msg = this.resolveGqlError(error);
					this.stateError.show = true;
				})
				.finally(() => (this.isLoadingDownload = false));
		},

		async getDocumentList() {
			this.documents = await getDocumentList({ showDocumentOnBoardingPackage: false }, this.currentJobRequest.targetProjectId);
		},
		handleClickSendPo() {
			this.showConfirmSendPoDialog = true;
		},
		handleConfirmSendPoDialogClose() {
			this.showConfirmSendPoDialog = false;
		}
	}
};
</script>
