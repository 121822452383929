<template>
	<div>
		<div class="sub-title">
			{{ FormMSG(5, 'Identification Numbers') }}
		</div>
		<b-row v-if="showInfoProjectAlreadyCreated && !userIsCreated" class="mt-2 mb-2">
			<b-col>
				<b-alert show variant="info" dismissible>
					<component :is="getLucideIcon('Info')" :size="16" /> {{ FormMSG(789, 'A project is already linked with the job request.') }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-if="showInfoProjectAlreadyCreated && userIsCreated" class="mt-2 mb-2">
			<b-col>
				<b-alert show variant="info" dismissible>
					<component :is="getLucideIcon('Info')" :size="16" />
					{{ FormMSG(792, 'A project is already linked with the job request and the user is linked.') }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row v-if="stateError.show" class="mt-2 mb-2">
			<b-col>
				<b-alert show variant="danger">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(45, 'Production company (Legal entity)')" />
					</template>
					<supplier-selector
						ref="selectorCompany"
						label=""
						:show-map="false"
						:title="titleSupllierSelector"
						:placeholder="FormMSG(105, 'Enter your legal entity name')"
						:addlabel="FormMSG(111, 'Add')"
						:update-label="FormMSG(112, 'Update')"
						type="projectAdmin"
						use-new-design
						:col-selector="6"
						:supplier-id="$v.jobRequest.supplierId.$model"
						@change="handleAdminOwnerChange"
						@supplier:unselected="onAdminOwnerUnselected"
					/>
					<div v-if="$v.jobRequest.supplierId.$error" class="invalid-feedback">
						{{ FormMSG(472, 'Please choice or create the legal entity') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(113, 'Contact name')">
					<b-form-input v-model="jobRequest.supplier.contactName" :placeholder="FormMSG(114, 'Enter contact name')" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(115, 'Contact email')">
					<b-form-input v-model="jobRequest.supplier.email" :placeholder="FormMSG(116, 'Enter contact email')" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(117, 'Contact phone')">
					<b-form-input v-model="jobRequest.supplier.phone" :placeholder="FormMSG(118, 'Enter contact phone')" />
				</b-form-group>
			</b-col>
		</b-row>

		<div class="sub-title">
			{{ FormMSG(119, 'Project information') }}
		</div>
		<b-row class="mt-3">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(289, 'Project name')" />
					</template>
					<b-form-input
						v-model="$v.jobRequest.projectName.$model"
						:placeholder="FormMSG(120, 'Enter project name')"
						:state="getStateValidationField('jobRequest.projectName')"
						:disabled="userIsCreated"
					/>
					<div v-if="$v.jobRequest.projectName.$error && getStateValidationField('jobRequest.projectName') !== null" class="invalid-feedback">
						{{ FormMSG(473, 'Please enter the project name') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(619, 'Production type')" />
					</template>
					<b-form-select
						v-model="$v.jobRequest.productionType.$model"
						:options="mapTgsproductionType"
						:state="getStateValidationField('jobRequest.productionType')"
						:disabled="userIsCreated"
					/>
					<div v-if="$v.jobRequest.productionType.$error && getStateValidationField('jobRequest.productionType') !== null" class="invalid-feedback">
						{{ FormMSG(474, 'Please choice the production type') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group label="" v-slot="{ ariaDescribedby }">
					<b-form-radio-group id="radio-group-2" v-model="projectChoice" :aria-describedby="ariaDescribedby" name="projectChoiceCompanyDetail">
						<b-form-radio :value="1" :disabled="userIsCreated">{{ FormMSG(125, 'Create as new project') }}</b-form-radio>
						<b-form-radio :value="2" :disabled="userIsCreated">{{ FormMSG(126, 'Existing project') }}</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>
		<b-collapse :visible="projectChoice === 1">
			<div v-if="projectChoice === 1">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(201, 'License ')" />
							</template>
							<v-select
								v-model="$v.newProject.licenseId.$model"
								label="content"
								:options="licenses"
								:reduce="(option) => +option.id"
								:placeholder="FormMSG(189, 'Please select ...')"
								:class="{ 'is-invalid': $v.newProject.licenseId.$error }"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.licenseId.$error" class="invalid-feedback">
								{{ FormMSG(475, 'Please select license') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(215, 'Production')" />
							</template>
							<b-form-input
								v-model="$v.newProject.production.$model"
								:placeholder="FormMSG(190, 'Enter production')"
								:state="getStateValidationField('newProject.production')"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.production.$error && getStateValidationField('newProject.production') !== null" class="invalid-feedback">
								{{ FormMSG(476, 'Please insert the production') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(216, 'Director')" />
							</template>
							<b-form-input
								v-model="$v.newProject.director.$model"
								:placeholder="FormMSG(191, 'Enter director')"
								:state="getStateValidationField('newProject.director')"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.director.$error && getStateValidationField('newProject.director') !== null" class="invalid-feedback">
								{{ FormMSG(477, 'Please insert the director') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(217, 'Language')" />
							</template>
							<b-form-select
								v-model="$v.newProject.language.$model"
								:options="optionsForLanguages"
								id="optionsForLanguages"
								:state="getStateValidationField('newProject.language')"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.language.$error && getStateValidationField('newProject.language') !== null" class="invalid-feedback">
								{{ FormMSG(487, 'Please select a language') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(218, 'Regional settings')" />
							</template>
							<b-form-select
								v-model="$v.newProject.regionalSetting.$model"
								:options="regionalSettingOptions"
								id="regionalSettingOptions"
								:state="getStateValidationField('newProject.regionalSetting')"
								:disabled="userIsCreated"
							/>
							<div
								v-if="$v.newProject.regionalSetting.$error && getStateValidationField('newProject.regionalSetting') !== null"
								class="invalid-feedback"
							>
								{{ FormMSG(478, 'Please select regional settings') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(219, 'Time zone')" />
							</template>
							<b-form-input
								v-model="$v.newProject.timeZone.$model"
								:state="getStateValidationField('newProject.timeZone')"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.timeZone.$error && getStateValidationField('newProject.timeZone') !== null" class="invalid-feedback">
								{{ FormMSG(479, 'Please enter Time zone') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(220, 'Currency')" />
							</template>
							<b-form-select
								id="currency"
								v-model="$v.newProject.currency.$model"
								:options="mapCurrencies"
								:state="getStateValidationField('newProject.currency')"
								:disabled="userIsCreated"
							/>
							<div v-if="$v.newProject.currency.$error && getStateValidationField('newProject.currency') !== null" class="invalid-feedback">
								{{ FormMSG(480, 'Please select currency') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(221, 'Distance unit')" />
							</template>
							<b-form-select
								id="distanceUnit"
								v-model="$v.newProject.distanceUnit.$model"
								:options="mapDistacneUnits"
								:state="getStateValidationField('newProject.distanceUnit')"
								:disabled="userIsCreated"
							/>
							<div
								v-if="$v.newProject.distanceUnit.$error && getStateValidationField('newProject.distanceUnit') !== null"
								class="invalid-feedback"
							>
								{{ FormMSG(476, 'Please select distance unit') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(235, 'Start date')" />
							</template>
							<v-date-picker :value="$v.newProject.startDate.$model" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInputStartDate">
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" :disabled="userIsCreated" />
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer" @click="togglePopover">
												<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</template>
							</v-date-picker>
							<div v-if="$v.newProject.startDate.$error && getStateValidationField('newProject.startDate') !== null" class="invalid-feedback">
								{{ FormMSG(498, 'Please insert start date') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(236, 'End date')" />
							</template>
							<v-date-picker
								:value="$v.newProject.endDate.$model"
								:masks="{ input: 'DD/MM/YYYY' }"
								:min-date="newProject.startDate"
								@input="handleInputEndDate"
							>
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" :disabled="userIsCreated" />
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer" @click="togglePopover">
												<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</template>
							</v-date-picker>
							<div v-if="$v.newProject.endDate.$error && getStateValidationField('newProject.endDate') !== null" class="invalid-feedback">
								{{ FormMSG(499, 'Please insert end date') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(241, 'Carbon calculator')">
							<b-form-select id="carbonCalculator" v-model="newProject.carbonModel" :options="mapCarbonModels" :disabled="userIsCreated" />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(242, 'Certification')">
							<b-form-select id="greenReporting" v-model="newProject.greenReporting" :options="mapGreenReporting" :disabled="userIsCreated" />
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</b-collapse>
		<b-collapse :visible="projectChoice === 2">
			<div v-if="projectChoice === 2">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group>
							<template #label>
								<custom-label-mandatory :label="FormMSG(250, 'Project selection')" />
							</template>
							<v-select
								v-model="$v.projectSelected.$model"
								:options="listProject"
								:reduce="(option) => +option.id"
								label="titre"
								:placeholder="FormMSG(189, 'Please select ...')"
								:class="{ 'is-invalid': $v.projectSelected.$error && getStateValidationField('projectSelected') }"
								:disabled="userIsCreated"
							>
								<template #option="{ titre, license }">
									<div class="d-flex">
										<div class="fw-700 mr-2">{{ titre }}</div>
										<div>({{ license.companyName }})</div>
									</div>
								</template>
							</v-select>
							<div v-if="$v.projectSelected.$error && getStateValidationField('projectSelected') !== null" class="invalid-feedback">
								{{ FormMSG(589, 'Please select a project') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</b-collapse>

		<b-row>
			<b-col cols="3" offset="9">
				<b-button variant="primary" block :disabled="loadingSubmitCreateProject" @click="handleClickBtnSave">
					<b-spinner v-if="loadingSubmitCreateProject" small />
					{{ saveLabel }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import SupplierSelector from '@/components/SupplierSelector.vue';
import _ from 'lodash';
import CustomLabelMandatory from './CustomLabelMandatory.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapGetters, mapActions } from 'vuex';
import { getLicenses } from '@/cruds/license.crud';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { createProjectFromJobRequest, updateJobRequestTargetProjectId } from '@/cruds/job-request.crud';
import { getAllProjects } from '@/cruds/project.crud';
import moment from 'moment';
import { noTilde } from '~utils';

export default {
	name: 'CompanyProjectDetail',

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		CustomLabelMandatory,
		'v-date-picker': DatePicker,
		SupplierSelector
	},

	data() {
		return {
			projectChoice: 1,

			jobRequest: {
				supplierId: null,
				projectName: null,
				productionType: null
			},
			licenses: [],
			loadingSubmitCreateProject: false,
			listProject: [],

			stateError: {
				show: false,
				msg: ''
			},

			newProject: {
				licenseId: null,
				production: null,
				director: null,
				language: 0,
				regionalSetting: 0,
				timeZone: 'CET',
				currency: 0,
				distanceUnit: 0,
				startDate: new Date(),
				endDate: new Date(),
				carbonModel: 0,
				greenReporting: 0
			},

			projectSelected: null
		};
	},

	watch: {
		jobRequest: {
			handler(newVal) {
				if (newVal.supplierId === null) {
					this.newProject.production = '';
				} else {
					setTimeout(() => {
						let company = this.$refs['selectorCompany'];
						let mapItems = company.mapItems;
						let data = mapItems.find((item) => +item.code === +newVal.supplierId);
						if (data) {
							this.newProject.production = data.label;
						} else {
							this.newProject.production = this.jobRequest.supplierName;
						}
					}, 800);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		currentJobRequest: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.jobRequest = _.cloneDeep(newVal);

					this.projectSelected = newVal.targetProjectId === 0 ? null : +newVal.targetProjectId;

					if (newVal.targetProjectId > 0) {
						this.projectChoice = 2;
					} else {
						this.projectChoice = 1;
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			currentJobRequest: 'job-request/currentJobRequest'
		}),

		userIsCreated() {
			return this.currentJobRequest.userCreated;
		},

		showInfoProjectAlreadyCreated() {
			if (this.currentJobRequest.targetProjectId > 0) {
				return true;
			}

			return false;
		},

		mapTgsProjectType() {
			return [{ value: null, text: this.FormMSG(2556, 'Please select ...') }, ...this.FormMenu(1025)];
		},

		mapGreenReporting() {
			let menus = this.FormMenu(1130);

			return menus;
		},
		mapCarbonModels() {
			return this.FormMenu(7);
		},
		mapDistacneUnits() {
			return this.FormMenu(1330);
		},

		mapCurrencies() {
			return this.FormMenu(5);
		},

		regionalSettingOptions() {
			let menus = this.FormMenu(11023);

			menus.sort((a, b) => {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}

				return 0;
			});

			return menus;
		},

		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},

		mapTgsproductionType() {
			let type = [
				{
					value: null,
					text: this.FormMSG(2556, 'Please select ...')
				},
				{
					label: this.FormMSG(1000, 'Film'),
					options: this.FormMenu(1350)
				},
				{
					label: this.FormMSG(1001, 'TV/ Audiovisual'),
					options: this.FormMenu(1351)
				},
				{
					label: this.FormMSG(1002, 'Shows'),
					options: this.FormMenu(1352)
				},
				{
					label: this.FormMSG(1003, 'Publicity'),
					options: this.FormMenu(1353)
				},
				{
					label: this.FormMSG(1004, 'Other'),
					options: this.FormMenu(1354)
				}
			];
			return type;
		},

		isUpdate() {
			return false;
		},
		titleSupllierSelector() {
			if (!this.isUpdate) {
				return this.FormMSG(101, 'Add entity details');
			}

			return this.FormMSG(102, 'Update entity details');
		},
		saveLabel() {
			if (this.projectChoice === 1) {
				return this.FormMSG(458, 'Create project');
			}

			return this.FormMSG(459, 'Save');
		}
	},

	async created() {
		await this.getLicenses();
		await this.getAllProjects();
	},

	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),

		async getAllProjects() {
			this.listProject = await getAllProjects();
		},

		onAdminOwnerUnselected() {
			this.jobRequest.supplierId = null;
		},

		handleAdminOwnerChange(supplier) {
			this.jobRequest.supplierId = +supplier.id;
			let company = this.$refs['selectorCompany'];
			if (company) {
				setTimeout(() => {
					let mapItems = company.mapItems;
					let data = mapItems.find((item) => +item.code === +supplier.id);
					if (data) {
						this.newProject.production = data.label;
					} else {
						this.newProject.production = '';
					}
				}, 100);
			}
		},

		async handleClickBtnSave() {
			try {
				if (this.projectChoice === 1) {
					this.$v.jobRequest.$touch();
					this.$v.newProject.$touch();

					if (this.$v.jobRequest.$invalid || this.$v.newProject.$invalid) return;

					this.loadingSubmitCreateProject = true;
					const dataToSend = {
						titre: this.jobRequest.projectName,
						productionType: this.jobRequest.productionType,
						licenseID: +this.newProject.licenseId,
						production: +this.newProject.production,
						realisateur: +this.newProject.director,
						language: +this.newProject.language,
						regionalSetting: +this.newProject.regionalSetting,
						timeZone: this.newProject.timeZone,
						currency: +this.newProject.currency,
						distanceUnit: +this.newProject.distanceUnit,
						startDate: moment(this.newProject.startDate).format(),
						endDate: moment(this.newProject.endDate).format(),
						carbonModel: +this.newProject.carbonModel,
						greenReporting: +this.newProject.greenReporting
					};

					const result = await createProjectFromJobRequest(dataToSend, this.currentJobRequest.id, this.jobRequest.supplierId);

					this.setCurrentJobRequest(result);

					await this.getAllProjects();
					this.createToastForMobile(this.FormMSG(845, 'Success'), this.FormMSG(846, 'Created project successfully!'));
					this.loadingSubmitCreateProject = false;
				} else {
					this.$v.projectSelected.$touch();

					if (this.$v.projectSelected.$invalid) return;

					this.loadingSubmitCreateProject = true;
					// debug project name
					const result = await updateJobRequestTargetProjectId(this.projectSelected, this.currentJobRequest.id);
					this.setCurrentJobRequest(result);
					setTimeout(() => {
						let setName = this.listProject.find((item) => +item.id === +this.projectSelected);
						if (setName) {
							this.jobRequest.projectName = setName.titre;
						}
					}, 100);
					this.createToastForMobile(this.FormMSG(845, 'Success'), this.FormMSG(1264, 'Project target is updated successfull!'));
					this.loadingSubmitCreateProject = false;
				}
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
				this.loadingSubmitCreateProject = false;
			}
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		},
		handleInputEndDate(payload) {
			this.newProject.endDate = payload;
		},
		handleInputStartDate(payload) {
			this.newProject.startDate = payload;

			if (new Date(this.newProject.startDate).getTime() > new Date(this.newProject.endDate).getTime()) {
				this.newProject.endDate = payload;
			}
		},
		async getLicenses() {
			if (this.currentJobRequest.defaultLicenseId) {
				this.newProject.licenseId = this.currentJobRequest.defaultLicenseId;
			}
			this.licenses = [...(await getLicenses())].map((item) => ({ ...item, content: `(${item.id}-${item.companyName}) ${item.license}` }));
		}
	},

	validations() {
		return {
			jobRequest: {
				supplierId: { required },
				projectName: { required },
				productionType: { required }
			},
			newProject: {
				licenseId: { required: this.projectChoice === 1 ? required : {} },
				production: { required: this.projectChoice === 1 ? required : {} },
				director: { required: this.projectChoice === 1 ? required : {} },
				language: { required: this.projectChoice === 1 ? required : {} },
				regionalSetting: { required: this.projectChoice === 1 ? required : {} },
				timeZone: { required: this.projectChoice === 1 ? required : {} },
				currency: { required: this.projectChoice === 1 ? required : {} },
				distanceUnit: { required: this.projectChoice === 1 ? required : {} },
				startDate: { required: this.projectChoice === 1 ? required : {} },
				endDate: { required: this.projectChoice === 1 ? required : {} }
			},
			projectSelected: { required: this.projectChoice === 2 ? required : {}, minValue: this.projectChoice === 2 ? minValue(1) : {} }
		};
	}
};
</script>
