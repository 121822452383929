var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "container-contract-detail-job-request" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.contracts, function (item) {
              return _c("contract", {
                key: item.id,
                attrs: {
                  generateKey: +item.id,
                  "contract-input": item,
                  accordion: _vm.accordion,
                  "hide-send-package-btn": false,
                  "show-pay-code": false,
                  "fill-class-container": "container-layout",
                  "hide-btn-delete": true,
                  "hide-btn-copy": true,
                  "hide-btn-save-template": true,
                  "from-job-request": true,
                  useMemoDeal: true,
                },
                on: { "contract:show-body": _vm.handleContractShowBody },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: "4",
                "offset-md": "8",
                lg: "4",
                "offset-lg": "8",
                xl: "4",
                "offset-xl": "8",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "primary",
                        disabled:
                          _vm.disableBtnUserInProject ||
                          _vm.loadingAddUserInProject ||
                          _vm.showInfoUserAlreadyAddInProject,
                      },
                      on: { click: _vm.handleClickAddUserInProject },
                    },
                    [
                      _vm.loadingAddUserInProject
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(487, "Add user in project")) +
                          "\n\t\t\t\t"
                      ),
                    ],
                    1
                  ),
                  _vm.disableBtnUserInProject
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass:
                            "btn-transparent text-color-tree-sap ml-2",
                          attrs: {
                            title: _vm.FormMSG(
                              497,
                              "You must create or select a project and linked with the job request"
                            ),
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("AlertTriangle"), {
                            tag: "component",
                            attrs: { size: 20 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showInfoUserAlreadyAddInProject
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass:
                            "btn-transparent text-color-blue-streak ml-2",
                          attrs: {
                            title: _vm.FormMSG(
                              1497,
                              "The user has already added in project"
                            ),
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("Info"), {
                            tag: "component",
                            attrs: { size: 20 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }